/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
/* eslint-disable camelcase */
import { Form, Select } from 'antd';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useMedicalFile } from '../../redux/medicalFile/selectors';
import { useRoles } from '../../redux/metadata/selectors';
import { fetchAllUsers } from '../../services/api';

function Interlocutor({ multiple = false, label, roleName, type = null, onSelect }) {
  const [medicalFile] = useMedicalFile();
  const { interlocutors } = medicalFile;
  const roles = useRoles();
  const [[users, isFetching], setUsers] = useState([[], true]);

  const loadUsers = useCallback(async () => {
    const u = await fetchAllUsers(roleName).catch(() => []);
    setUsers([u, false]);
  }, [roleName]);

  useEffect(() => {
    loadUsers();
  }, [loadUsers]);

  const role = roles.find((r) => r.name === roleName);
  const defaultInterlocutors = useMemo(
    () =>
      multiple
        ? interlocutors
            .filter((f) => f.type === (type === null ? roleName : type))
            .map((f) => f.interlocutor.id)
        : interlocutors.find((f) => f.type === roleName),
    [interlocutors, roleName, type, multiple]
  );

  return (
    <Form.Item label={label}>
      <Select
        onSelect={(value) =>
          onSelect(
            {
              user_id: value,
              type: role?.id,
              viewer: roleName === 'Expert Doctor' && multiple,
            },
            role
          )
        }
        filterOption={(input, option) =>
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        defaultValue={multiple ? defaultInterlocutors : defaultInterlocutors?.interlocutor.id}
        mode={multiple ? 'multiple' : ''}
        showSearch
        allowClear
        loading={isFetching}
      >
        {users.map((m) => (
          <Select.Option key={m.id} value={m.id}>
            {`${m.firstname} ${m.lastname} (${m.email})`}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );
}

export default Interlocutor;
