import React from 'react';
import { Redirect } from 'react-router-dom';

const getNewUrl = () => {
  switch (process.env.REACT_APP_ENV) {
    default:
      return 'https://fs-test.france-surgery.com/api/v1';
  }
};

const getUrl = () => {
  switch (process.env.REACT_APP_ENV) {
    default:
      return 'https://digimed-preprod.france-surgery.com';
  }
};

export const isOnAxaWebsite = () =>
  window.location.origin === 'https://axabilan.france-surgery.com';

export const url = getUrl();
export const api = getNewUrl();

const headersFile = { Accept: 'application/json' };

const request = async (path, method = 'GET', body, isFile, redirect = true) => {
  const headers = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    'Accept-Language': localStorage.getItem('i18nextLng'),
  };

  const response = await fetch(`${url}${path}`, {
    method,
    headers: isFile ? headersFile : headers,
    body: isFile ? body : body && JSON.stringify(body),
    credentials: 'include',
  });

  const hasToRedirect =
    redirect &&
    window.location.pathname.split('/').pop() !== 'login' &&
    window.location.pathname.split('/').pop() !== 'logout';

  if (response.status === 401 && hasToRedirect) return window.location.assign('/logout');

  const json = await response.json().catch(() => null);
  if (!response.ok) throw json;
  return json;
};

export const requestV2 = async (path, method = 'GET', body, isFile) => {
  const headers = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    'Accept-Language': localStorage.getItem('i18nextLng'),
  };
  const response = await fetch(`${api}${path}`, {
    method,
    headers,
    body: body && JSON.stringify(body),
    credentials: 'include',
  });
  if (response.status === 401) {
    return <Redirect to="/logout" />;
  }
  const json = await response.json().catch(() => null);
  if (!response.ok) throw json;
  return json;
};

export const fetchFile = async (path) => {
  const response = await fetch(`${url}${path}`, {
    method: 'GET',
    headers: { Accept: 'application/json' },
    credentials: 'include',
  });
  return response;
};

export const fetchJson = async (path) => {
  const response = await fetch(`${url}${path}`, {
    method: 'GET',
    headers: { Accept: 'application/json' },
    credentials: 'include',
  });
  return response.json();
};

// from https://stackoverflow.com/questions/56429933/is-it-possible-to-get-file-type-before-downloading-it-through-javascript
export const getMimeType = (reqUrl) => {
  const req = new XMLHttpRequest();
  req.open('HEAD', reqUrl);
  const promise = new Promise((resolve) => {
    req.onreadystatechange = () => {
      if (req.readyState === req.DONE) {
        resolve(
          req
            .getAllResponseHeaders()
            .split('\n')
            .find((header) => header.toLowerCase().startsWith('content-type'))
            .split(':')[1]
            .trim()
        );
      }
    };
  });

  req.send();
  return promise;
};

export default request;
