import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Form, Input, message, Space, Switch } from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import Title from 'antd/lib/typography/Title';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import Flex from '../../components/Flex';
import TableCustom from '../../components/Table';
import useHasPermission from '../../hooks/useHasPermission';
import { fetchUsers, deleteUser } from '../../services/api';

const styles = { icon: { fontSize: 18, cursor: 'pointer' } };

function ListUsers() {
  const [[state, isFetching], setState] = useState([null, true]);
  const history = useHistory();
  const { t } = useTranslation();
  const hasPermission = useHasPermission();

  const fetchData = (page, order) =>
    fetchUsers(page, order)
      .then((s) => setState([s, false]))
      .catch(() => setState([null, false]));

  useEffect(() => {
    fetchData();
  }, []);

  const removeUser = async (id) => {
    try {
      setState(([c]) => [c, true]);
      await deleteUser(id);
      await fetchData(state.meta.current_page);
      message.success(t('FRONT_NOTIFICATION_DELETE_SUCCESS'));
    } catch (err) {
      setState(([c]) => [c, false]);
      message.error(t('FRONT_NOTIFICATION_DELETE_FAILED'));
    }
  };

  const columns = [
    { title: 'id', dataIndex: 'id' },
    {
      title: t('FRONT_USER_ROLE'),
      dataIndex: 'roles',
      render: (values) => values.map((v) => v.alias).join(', '),
      sorter: true,
    },
    { title: t('FRONT_USER_ENTITY'), dataIndex: ['company', 'name'], sorter: true },
    { title: t('FRONT_USER_FIRST_NAME'), dataIndex: 'firstname', sorter: true },
    { title: t('FRONT_USER_LAST_NAME'), dataIndex: 'lastname', sorter: true },
    { title: t('FRONT_USER_EMAIL'), dataIndex: 'email', sorter: true },
    {
      key: 'action',
      render: (_, item) => (
        <Space size="large">
          <Link to={{ pathname: 'users/add', state: { user: item } }}>
            <EditOutlined style={styles.icon} />
          </Link>
          {hasPermission('delete users') && (
            <DeleteOutlined
              onClick={() => removeUser(item.id)}
              style={{ ...styles.icon, color: 'red' }}
            />
          )}
        </Space>
      ),
    },
  ];

  return (
    <div>
      <Title>{t('Users')}</Title>
      <Flex style={{ justifyContent: 'space-between' }}>
        <Form
          layout="inline"
          style={{ marginBottom: 10 }}
          onFinish={(v) => history.push('/users/search', v)}
        >
          <Form.Item label={t('FRONT_USER_LAST_NAME')} name="lastname">
            <Input />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              {t('FRONT_FORM_SEARCH')}
            </Button>
          </Form.Item>
        </Form>
      </Flex>
      <TableCustom state={state} isFetching={isFetching} columns={columns} fetchData={fetchData} />
    </div>
  );
}

export default ListUsers;
