import { Form, Switch } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { SelectDelay, SelectAssistance, SelectRegion } from './form';

const styles = {
  wrapper: { flexDirection: 'row', alignItems: 'baseline' },
  switch: { marginLeft: 16 },
};

function T4T() {
  const { t } = useTranslation();

  return (
    <>
      <SelectDelay type={1} />
      <SelectAssistance />
      <SelectRegion />
      <Form.Item
        style={styles.wrapper}
        label={t('FRONT_MEDICAL_FILE_RECOVERYSTAY')}
        name="recoverystay"
        valuePropName="checked"
      >
        <Switch style={styles.switch} />
      </Form.Item>
      <Form.Item
        style={styles.wrapper}
        label={t('FRONT_MEDICAL_FILE_VISA')}
        name="visa"
        valuePropName="checked"
      >
        <Switch style={styles.switch} />
      </Form.Item>
    </>
  );
}

export default T4T;
