import { Steps } from 'antd';
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Workflow from '..';
import { useMedicalFile } from '../../../redux/medicalFile/selectors';
import { fetchStep, nextStep } from '../../../redux/step/actions';
import { useStep } from '../../../redux/step/selectors';
import Loading from '../../layouts/Loading';

function HCWorflow() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [, isFetching] = useStep();
  const [medicalFile] = useMedicalFile();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(fetchStep(medicalFile.reference));
  }, [dispatch, medicalFile.reference]);

  const steps = useMemo(
    () => [
      {
        reference: 'patient.stepfillmedicalform',
        next: () => history.push(`/medicals/${medicalFile.reference}/health`),
      },
      {
        reference: 'patient.stepuploadmedicalfiles',
        next: () => history.push(`/medicals/${medicalFile.reference}/documents`, { defaultTab: 1 }),
        shouldValidate: true,
      },
      {
        reference: 'admin.stepassigndoctor',
        next: () => history.push(`/medicals/${medicalFile.reference}`, { edit: true }),
      },
      {
        reference: 'doctor.stepvalidatemedicalfile',
        next: () => history.push(`/medicals/${medicalFile.reference}/documents`, { defaultTab: 2 }),
        shouldValidate: true,
      },
      {
        reference: 'system.stepcommercialproposition',
        next: () =>
          history.push(`/medicals/${medicalFile.reference}/management`, { defaultTab: 1 }),
        shouldValidate: true,
      },
      {
        reference: 'patient.stepvalidatecommercialproposition',
        next: () =>
          history.push(`/medicals/${medicalFile.reference}/management`, { defaultTab: 3 }),
        shouldValidate: true,
        doubleCheck: t('FRONT_MEDICAL_FILE_STEP_CONFIRM_COMMERCIAL_PROPOSITION'),
      },
      {
        reference: 'system.stepwaitingforadvancepayment',
        next: () => {
          // eslint-disable-next-line no-alert
          const r = window.confirm(t('FRONT_MEDICAL_FILE_STEP_CONFIRM_DEPOSIT'));
          if (r) dispatch(nextStep(medicalFile.reference));
        },
      },
      {
        reference: 'system.stepwaitingforcheckupdata',
        next: () => history.push(`/medicals/${medicalFile.reference}`, { edit: true }),
        shouldValidate: true,
      },
      {
        reference: 'patient.stepfilltravelform',
        next: () => history.push(`/medicals/${medicalFile.reference}/travel`),
      },
      {
        reference: 'system.stepwaitingfortraveldata',
        next: () => history.push(`/medicals/${medicalFile.reference}`, { edit: true }),
        shouldValidate: true,
      },
      {
        reference: 'system.stepwaitingforcheckupconvocation',
        next: () =>
          history.push(`/medicals/${medicalFile.reference}/management`, { defaultTab: 1 }),
        shouldValidate: true,
      },
      {
        reference: 'patient.viewcheckupconvocation',
        next: () =>
          history.push(`/medicals/${medicalFile.reference}/management`, { defaultTab: 2 }),
      },
      {
        reference: 'system.stepwaitingforcheckup',
        next: () => {
          dispatch(nextStep(medicalFile.reference));
        },
      },
      {
        reference: 'system.stepcheckupinprogress',
        next: () => {
          dispatch(nextStep(medicalFile.reference));
        },
      },
      {
        reference: 'doctor.stepanalysisinprogress',
        next: () => history.push(`/medicals/${medicalFile.reference}/documents`, { defaultTab: 1 }),
        shouldValidate: true,
      },
      {
        reference: 'patient.viewcheckupresults',
        next: () => history.push(`/medicals/${medicalFile.reference}/documents`, { defaultTab: 3 }),
      },
      {
        reference: 'doctor.stepfillcheckupform',
        next: () => history.push(`/medicals/${medicalFile.reference}/checkupform`),
      },
      {
        reference: 'system.stepinvoice',
        next: () =>
          history.push(`/medicals/${medicalFile.reference}/management`, { defaultTab: 1 }),
        shouldValidate: true,
      },
      {
        reference: 'system.stepwaitingforpayment',
        next: () => history.push(`/medicals/${medicalFile.reference}`, { edit: true }),
        shouldValidate: true,
      },
      {
        reference: 'patient.stepfillsatisfactionform',
        next: () => history.push(`/medicals/${medicalFile.reference}/satisfaction`),
      },
      {
        reference: 'system.fileclosed',
        next: null,
      },
    ],
    [dispatch, t, history, medicalFile]
  );
  if (isFetching) return <Loading />;
  return <Workflow steps={steps} />;
}

export default HCWorflow;
